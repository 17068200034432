import React, {useState, useEffect, useContext, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
// Firebase
import { FirebaseContext } from "../../../firebase/"
// Redux
import {useDispatch} from "react-redux"
import { PlayNotifications } from 'redux/notifications/PlayNotifications'
import LayoutDashboard from "components/layout/LayoutDashboard";
import {PROJECTS_MENU} from "utils/constantsBackOffice";
import {RiArrowLeftLine, RiBuildingLine, RiLoader4Line} from "react-icons/ri";
import Button from "components/elements/button/Button";
import Headings from "components/elements/Typography/Headings";
import _ from "lodash";
import {capitalizeFirstLetter} from "utils/stringUtils";
import moment from "moment/moment";

const ProjectPage = ({ selectedMenu, currentUser }) => {
  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard'])

  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()

  let currentUrl = useParams() // observes a change of url
  const [currentId, ] = useState(currentUrl.id ? currentUrl.id : null)

  const [ready, setReady] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [state, setState] = useState({})

  const availableOptions = [
    {id: 'api', name: t('projects.options.Api')},
    {id: 'ai.prompts_families', name: t('projects.options.Ai-prompts-families')},
    {id: 'beta.search', name: t('projects.options.Beta-search')},
    {id: 'content.edit_publication_date', name: t('projects.options.Edit-publication-date')},
    {id: 'gtm', name: t('projects.options.Google-tag-manager')},
    {id: 'homepage.create', name: t('projects.options.Focus-page')},
    {id: 'import.import_content', name: t('projects.options.Import-content')},
    {id: 'import.import_structure', name: t('projects.options.Import-structures')},
    {id: 'import.import_attributes', name: t('projects.options.Import-attributes')},
    {id: 'sort.reference_date', name: t('projects.options.Reference-date')},
    {id: 'sort.reference_date_setting', name: t('projects.options.Reference-date-setting')},
    {id: 'theme.custom_colors', name: t('projects.options.Custom-colors')}
  ];

  const setOption = (option, activated) => {
    console.log('setOption')
    setFormLoading(true)
    // setEntityOptions
    const setEntityOptionsFunction = firebase.functions.httpsCallable("setEntityOptions");
    setEntityOptionsFunction({
      options: {
        [option]: activated
      },
      project_id: state.id
    }).then((result) => {
      console.log('success !!', result)
      setFormLoading(false)

      if(result.data?.success === true){
        firebase.db.collection('projects').doc(currentId).update({
          [`options.${option}`]: activated
        }).catch((e)=>{
          console.error('Error during update', e)
        })

        dispatch(
          PlayNotifications({
            display: true,
            theme: 'success',
            message: t('success.saved-changes', {ns: 'messages'}),
          })
        )
      } else {
        dispatch(
          PlayNotifications({
            display: true,
            theme: 'danger',
            message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
          })
        )
      }
    }).catch((error) => {
      setFormLoading(false)
      dispatch(
        PlayNotifications({
          display: true,
          theme: 'danger',
          message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
        })
      )
      console.error(error);
    })
  }

  const getOptionStatus = (option) => {
    return _.get(state, `options.${option}`) === true
  }

  const getOptionStatusTag = (option) => {
    if( _.get(state, `options.${option}`) === true ){
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-green-100 border border-green-300 text-xs font-semibold text-green-600 inline rounded-full p-0.5 px-2">
          {t('status.Activated', {ns: 'dashboard'})}
        </span>
      )
    } else if (_.get(state, `options.${option}`) === false) {
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-gray-100 border border-gray-300 text-xs font-semibold text-gray-600 inline rounded-full p-0.5 px-2">
          {t('status.Disabled', {ns: 'dashboard'})}
        </span>
      )
    } else if( _.get(state, `options.${option}`) === undefined ){
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-red-100 border border-red-300 text-xs font-semibold text-red-600 inline rounded-full p-0.5 px-2">
          {t('status.Unset', {ns: 'dashboard'})}
        </span>
      )
    }

    return '-'
  }

  useEffect(() => {
    document.title = `Memory - ${t('projects.Projects-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let projectSnapUnsubscribe

    if(currentId){
      projectSnapUnsubscribe = firebase.db.collection('projects').doc(currentId).onSnapshot( async doc => {
        if (doc.exists) {
          const data = {...doc.data(), id: doc.id}
          setState(data)
          setReady(true)
        } else {
          setReady(true)
        }
      })
    }

    return () => {
      projectSnapUnsubscribe && projectSnapUnsubscribe()
    }
  }, [currentId])

  return (
    <LayoutDashboard
      currentUser={currentUser}
      // headerNav={SUB_NAV_SETTINGS}
      headerNavSelected={PROJECTS_MENU}
      selectedMenu={selectedMenu}
      maxHeight={false}
    >
      <div id="sections-container" className="flex flex-col justify-between w-full h-full max-w-100vw overflow-auto memoryScrollbar">
        <div className="flex flex-col relative">
          <header className="z-40 bg-white w-full pb-6 sticky top-0">
            <ul className="flex items-center justify-between mb-2">
              <li className="flex items-center gap-2">
                <RiBuildingLine className="w-5 h-5 min-w-5"/>
                <Headings
                  variant="h2"
                  className="flex items-center"
                  display="display-4"
                >
                  {t('projects.Project', {ns: 'dashboard'})}: {state.name ? state.name : state.id}
                </Headings>
              </li>
            </ul>
          </header>

          <div className="flex w-full relative">
            <div className="w-full pb-5 space-y-2">
              <div className={`flex flex-col w-full h-full pb-2 pr-4 pb-0 bg-white overflow-visible`}>

                <div className={`mb-4 text-xl font-semibold`}>
                  {t('projects.Informations', {ns: 'dashboard'})}
                </div>
                <div className={`flex flex-col gap-4`}>
                  <div className={`flex flex-col gap-1`}>
                    <span className={``}>{t('Name', {ns: 'common'})}</span>
                    <span className={`font-semibold`}>{state.name ? state.name : t('NC', {ns: 'dashboard'})}</span>
                  </div>

                  <div className={`flex flex-col gap-1`}>
                    <span className={``}>{t('projects.Domain-subdomain', {ns: 'dashboard'})}</span>
                    <span
                      className={`font-semibold`}>{state.domain ? state.domain : state.subdomain ? state.subdomain : t('NC', {ns: 'dashboard'})}</span>
                  </div>

                  <div className={`flex flex-col gap-1`}>
                    <span className={``}>{t('Plan', {ns: 'common'})}</span>
                    <span className={`font-semibold`}>{state.plan ? t(capitalizeFirstLetter(state.plan), {ns: 'common'}) : t('NC', {ns: 'dashboard'})}</span>
                  </div>

                  <div className={`flex flex-col gap-1`}>
                    <span className={``}>{t('date.Creation-date', {ns: 'common'})}</span>
                    <span className={`font-semibold`}>{state.created_at ? moment(state.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}</span>
                  </div>
                </div>

                <div className={`mt-16 mb-4 text-xl font-semibold`}>
                  {t('projects.Set-options', {ns: 'dashboard'})}
                </div>
                <div className={`flex flex-col gap-2`}>
                  {availableOptions.map((option, index) => (
                    <div key={index} className={`flex py-2 px-4 gap-4 hover:bg-gray-100 items-center justify-between`}>
                      <div className={`flex gap-4`}>
                        <span>
                          {t(option.name, {ns: 'dashboard'})}
                        </span>
                        <span>
                          {getOptionStatusTag(option.id)}
                        </span>
                      </div>

                      <div className={``}>
                        <button
                          onClick={() => setOption(option.id, !getOptionStatus(option.id) === true)}
                          className={`flex items-center gap-x-2 border px-2 py-1 rounded cursor-pointer text-sm bg-white ${formLoading ? 'opacity-50' : 'opacity-100'}`}
                          disabled={formLoading}
                        >
                          {formLoading && <RiLoader4Line className="w-4 h-4 animate-spin"/>}
                          {getOptionStatus(option.id) === true ?
                            t('action.Disable', {ns: 'common'})
                            :
                            t('action.Enable', {ns: 'common'})
                          }
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="z-50 flex shrink-0 items-center mt-2 border-t-2 border-gray-400 bg-gray-100 justify-between pb-6 pt-6 px-4 space-x-2 sticky bottom-0">
          <Button
            actionType="route"
            action="/dashboard/projects/projects"
            theme={formLoading ? 'disabled' : 'simple'}
            size="small"
            icon={<RiArrowLeftLine className="mr-1"/>}
          >
            {t('action.Back')}
          </Button>
        </div>
      </div>
    </LayoutDashboard>
  )
}

export default ProjectPage