import React, {useEffect, useState, useContext, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { FirebaseContext } from '../firebase';
import { useParams } from 'react-router-dom';
import PageContentLoader from "../components/elements/loader/PageContentLoader";
import slugify from "react-slugify";
import Button from "../components/elements/button/Button";
import {RiCheckLine, RiCloseCircleLine, RiErrorWarningLine, RiLoader4Line} from "react-icons/ri";
import {RxRocket} from "react-icons/rx";
import {Fade, Tooltip, tooltipClasses} from "@mui/material";
import { styled } from '@mui/material/styles';
import Logo from '../assets/images/app/svg/logo-header-request.svg';
import ElephantStatic from '../assets/images/app/png/elephant.png';
import PlanStatic from '../assets/images/app/png/plan.png';
import SeahorseStatic from '../assets/images/app/png/seahorse.png';
import CoffeeAnimated from '../assets/images/app/gif/coffee-cup.gif';
import ElephantAnimated from '../assets/images/app/gif/elephant.gif';
import PlanAnimated from '../assets/images/app/gif/plan.gif';
import RocketAnimated from '../assets/images/app/gif/rocket.gif';
import SeahorseAnimated from '../assets/images/app/gif/seahorse.gif';
import LocalesSelector from "components/elements/locale/LocalesSelector";
import {LOCALES_OBJECT} from "../utils/constantsFront";
import Elephant from "assets/images/app/elephant-rocket.jpeg";


const EntityRequest = () => {
  // Translations
  const { t, i18n } = useTranslation(['common', 'messages', 'entity-request']);
  const currentLocale = i18n.language;

  const firebase = useContext(FirebaseContext)
  const notInitialRender = useRef(false)
  const initialLocale = useRef(null)

  let urlParams = useParams()
  const docId = urlParams.id

  const [entityRequest, setEntityRequest] = useState(null)
  const [project, setProject] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [ready, setReady] = useState(false)
  const [, setEntityNameMissing] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [isDisabled, ] = useState(false)
  const [domainLoading, setDomainLoading] = useState(false)
  const [domainAvailable, setDomainAvailable] = useState(false)
  const [memoryFinished, setMemoryFinished] = useState(false)
  const [entityFormName, setEntityFormName] = useState('')
  const [entitySlug, setEntitySlug] = useState('')
  const [plan, setPlan] = useState('demo')
  const [memoryLocale, setMemoryLocale] = useState(currentLocale)

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#000',
      backgroundColor: '#ffffff',
      maxWidth: 320,
      border: '1px solid #dadde9',
      boxShadow: theme.shadows[3],
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #dadde9"
      }
    },
  }))

  useEffect(() =>{
    let requestUnsubscribe
    let projectUnsubscribe

    if (docId && docId.length > 0) {
      requestUnsubscribe = firebase.db
        .collection('entity-request')
        .doc(docId)
        .onSnapshot(async(doc) => {
          if (doc.exists) {
            const data = doc.data()
            setEntityRequest(data)
            if( data.entity_name ){
              setEntityFormName(data.entity_name)
            } else {
              setEntityNameMissing(true)
            }

            if(data.language_version){
              setMemoryLocale(data.language_version === 'english' ? 'en_GB' : 'fr_FR')
            }

            console.log(data.language_version)
            if(data.hasOwnProperty('language_version')){
              // LOCALES_OBJECT
              const requestLocale = LOCALES_OBJECT.filter(item => item.name === data.language_version).shift();
              console.log(requestLocale)

              if(requestLocale && requestLocale.code !== currentLocale){
                console.log('request locale', initialLocale.current)
                if(initialLocale.current === null){
                  console.log('change locale', initialLocale.current)
                  initialLocale.current = requestLocale.code;
                  i18n.changeLanguage(requestLocale.code);
                }
              }
            }

            if( data.status !== undefined && data.status !== 'request' ){
              projectUnsubscribe = firebase.db
                .collection('projects')
                .where('project_id','==', data.project_id)
                .limit(1)
                .onSnapshot(async(snapshot) => {
                  if (snapshot.docs.length > 0) {
                    let document = snapshot.docs[0].data()
                    // document.build_status = 'FAILURES'
                    // document.app_build_status = 'FAILURE'
                    // document.backend_build_status = 'FAILURES'
                    console.log(`build_status :: ${document.build_status}`)
                    console.log(`app_build_status :: ${document.app_build_status}`)
                    console.log(`backend_build_status :: ${document.backend_build_status}`)

                    setProject(document)
                    setProjectId(snapshot.docs[0].id)
                    setMemoryFinished(document.build_status === 'SUCCESS' && document.app_build_status === 'SUCCESS' && document.backend_build_status === 'SUCCESS')
                  }

                  setReady(true)
                })
            } else {
              setReady(true)
            }
          } else {
            setReady(true)
          }
        })
    }

    return () => {
      if (requestUnsubscribe) {
        requestUnsubscribe()
      }
      if (projectUnsubscribe) {
        projectUnsubscribe()
      }
    }
  }, [docId])

  useEffect(() => {
    setEntitySlug(slugify(entityFormName))
    setDomainLoading(true)
    setDomainAvailable(true)
    if(notInitialRender.current){
      const timeOutId = setTimeout(() => verifyDomain(), 500);
      return () => clearTimeout(timeOutId);
    } else {
      notInitialRender.current = true
    }
  }, [entityFormName])

  const verifyDomain = () => {
    if( entityFormName && entityFormName.length > 1 ){
      firebase.db
        .collection('reserved-subdomains')
        .doc(`${slugify(entityFormName)}`)
        .get()
        .then((doc) => {
          if(!doc.exists){
            firebase.db
              .collection('projects')
              .where('subdomain', '==', slugify(entityFormName))
              .get()
              .then((snapshots) => {
                setDomainAvailable(snapshots.docs.length === 0)
                setDomainLoading(false)
              })
          } else {
            setDomainAvailable(!doc.exists)
            setDomainLoading(false)
          }
        })
    } else {
      setDomainAvailable(false)
      setDomainLoading(false)
    }
  }

  const handleChange = (e) => {
    setPlan(e.target.value)
  }

  const createEntity = () => {
    const deployFunction = firebase.functions.httpsCallable('deployTopicPub')
    setFormLoading(true)

    deployFunction({
      branch: entityRequest.branch ? entityRequest.branch : 'main',
      plan: plan,
      entityName: entityFormName,
      entityRequestId: docId,
      default_locale: memoryLocale,
      levelRequest: 'level-1'
    }).then((result) => {
      const resultData = result.data
      console.log('result ::', result)
      if(resultData.success !== true){
        if(resultData.error === true && resultData.message){
          switch (resultData.message){
            case 'already-requested':
              break
            case 'domain-reserved':
              setDomainAvailable(false)
              break
            case 'subdomain-unavailable':
              setDomainAvailable(false)
              break
            case 'project-id-unavailable':
              setDomainAvailable(false)
              break
            default:
              break
          }
          console.error('Deploy failed with error : ', resultData.message)
        }
      }

      setFormLoading(false)
    }).catch((error) => {
      console.error('Error ::', error)
      setFormLoading(false)
    })
  }

  const gcpProjectStatus = () => {
    if( project.build_status === undefined ){
      return
    }

    switch (project.build_status){
      case 'CANCELLED':
      case 'TIMEOUT':
      case 'FAILURE':
        return (
          <>
            <img src={PlanStatic} className="w-14 h-14" alt="" />
            {t(`building-infrastructure-progress`, {ns:'entity-request'})}
            <RiCloseCircleLine className="w-6 h-6 text-red-600 rounded-full bg-red-100" />
          </>
        )

      case 'SUCCESS':
        return (
          <>
            <img src={PlanStatic} className="w-14 h-14" alt="" />
            {t(`building-infrastructure-progress`, {ns:'entity-request'})}
            <RiCheckLine className="w-6 h-6 text-green-600 border border-green-500 rounded-full bg-green-100" />
          </>
        )

      case 'WORKING':
      case 'REQUESTED':
      case 'QUEUED':
      default:
        return (
          <>
            <img src={PlanAnimated} className="w-14 h-14" alt="" />
            {t(`building-infrastructure-progress`, {ns:'entity-request'})}
            <RiLoader4Line className="w-6 h-6 animate-spin text-grayPrimary" />
          </>
        )
    }
  }

  const frontendStatus = () => {
    // if( project.app_build_status === undefined ){
    //   return
    // }

    switch (project.app_build_status){
      case 'SUCCESS':
        return (
          <>
            <img src={SeahorseStatic} className="w-14 h-14" alt="" />
            {t(`building-frontend-progress`, {ns:'entity-request'})}
            <RiCheckLine className="w-6 h-6 text-green-600 border border-green-500 rounded-full bg-green-100" />
          </>
        )

      case 'CANCELLED':
      case 'TIMEOUT':
      case 'FAILURE':
        return (
          <>
            <img src={SeahorseStatic} className="w-14 h-14" alt="" />
            {t(`building-frontend-progress`, {ns:'entity-request'})}
            <RiCloseCircleLine className="w-6 h-6 text-red-600 rounded-full bg-red-100" />
          </>
        )

      case 'WORKING':
      default:
        return (
          <>
            <img src={SeahorseAnimated} className="w-14 h-14" alt="" />
            {t(`building-frontend-progress`, {ns:'entity-request'})}
            <RiLoader4Line className="w-6 h-6 animate-spin text-grayPrimary" />
          </>
        )
    }
  }

  const backendStatus = () => {
    // if( project.backend_build_status === undefined ){
    //   return
    // }

    switch (project.backend_build_status){
      case 'SUCCESS':
        return (
          <>
            <img src={ElephantStatic} className="w-14 h-14" alt="" />
            <span>{t(`building-backend-progress`, {ns:'entity-request'})}</span>
            <RiCheckLine className="w-6 h-6 text-green-600 border border-green-500 rounded-full bg-green-100" />
          </>
        )

      case 'CANCELLED':
      case 'TIMEOUT':
      case 'FAILURE':
        return (
          <>
            <img src={ElephantStatic} className="w-14 h-14" alt="" />
            <span>{t(`building-backend-progress`, {ns:'entity-request'})}</span>
            <RiCloseCircleLine className="w-6 h-6 text-red-600 rounded-full bg-red-100" />
          </>
        )

      case 'WORKING':
      default:
        return (
          <>
            <img src={ElephantAnimated} className="w-14 h-14" alt="" />
            <span>{t(`building-backend-progress`, {ns:'entity-request'})}</span>
            <RiLoader4Line className="w-6 h-6 animate-spin text-grayPrimary" />
          </>
        )
    }
  }

  if(!ready){
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <PageContentLoader />
      </div>
    )
  }

  return (
    <div className="min-h-screen">
      <div className="mx-auto min-h-screen items-center">

        {(entityRequest && project && (entityRequest.status !== undefined && entityRequest.status !== 'request')) && (
          <div className="h-fit mx-auto sm:flex sm:flex-col items-center justify-center px-5 md:px-0 pt-2 pb-16">
            <header className={`max-w-6xl w-full text-right items-center justify-end text-xs flex gap-x-2 mb-16`}>
              {LOCALES_OBJECT.map((locale, id) => (
                <div
                  className={`flex items-center gap-x-2 cursor-pointer py-2 hover:bg-gray-100 ${currentLocale === locale.code ? 'font-semibold' : ''}`}
                  onClick={(e) => {
                    i18n.changeLanguage(locale.code)
                  }}
                >
                  {locale.icon}
                  {t(locale.name_locale.key, {ns: locale.name_locale.ns})}
                </div>
              ))}
            </header>

            <header className="text-center w-full">
              <img src={Logo} alt="Memory" className="mb-16 sm:mb-24 mx-auto" />
            </header>
            <div className="mb-24 flex flex-col items-center text-center">
              {!memoryFinished && (
                <>
                  <img src={CoffeeAnimated} className="w-14 h-14 mb-2" alt="" />
                  {t('we-are-building-your-memory', {ns:'entity-request'})}
                  <span className="text-grayPrimary text-xs">
                      {t('building-your-memory-notice', {ns:'entity-request'})}
                    </span>
                </>
              )}
              {memoryFinished && (
                <>
                  <img src={RocketAnimated} className="w-14 h-14 mb-2" alt="" />
                  <span>{t('congrats', {ns:'entity-request'})}</span>
                  <span>{t('your-memory-is-ready', {ns:'entity-request'})}</span>
                </>
              )}
            </div>
            {/*{project.build_status === 'FAILURE' && (*/}
            {/*  <div className="flex gap-y-1 items-center text-red-600">*/}
            {/*    <RiCloseCircleLine className="w-6 h-6 rounded-full bg-red-200" />*/}
            {/*    {t(`entity-deploy-status-${project.build_status.toLowerCase()}`, {ns:'entity-request'})}*/}
            {/*  </div>*/}
            {/*)}*/}

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-8 sm:gap-x-12 items-center text-center max-w-4xl">
              <div className="flex flex-col gap-y-2 items-center">
                {gcpProjectStatus()}
              </div>
              <div className="flex flex-col gap-y-2 items-center">
                {backendStatus()}
              </div>
              <div className="flex flex-col gap-y-2 items-center">
                {frontendStatus()}
              </div>
            </div>

            <p className="mt-10 w-full">
              <Button
                className="mx-auto"
                theme={!memoryFinished ? 'disabled' : ''}
                size="small"
                icon={formLoading ? <RiLoader4Line className="w-4 h-4 mr-1 animate-spin" /> : <RiCheckLine className="w-4 h-4 mr-1" />}
                iconPosition="left"
                actionType="externalLink"
                action={`https://${projectId}.web.app`}
              >
                {t('access-your-memory', {ns: 'entity-request'})}
              </Button>
            </p>
          </div>
        )}


        {/* SETP 1 */}
        {(entityRequest && (entityRequest.status === undefined || entityRequest.status === 'request')) && (
          <div className="min-h-screen">
            <div className={`grid grid-cols-1 md:grid-cols-2 w-full`}>
              <div className="min-h-screen mx-auto sm:flex sm:flex-col items-center px-2 md:px-0">
                <header className={`w-full text-right items-center justify-end text-xs flex gap-x-2 pt-4 pr-4 mb-16 md:mb-24`}>
                  {LOCALES_OBJECT.map((locale, id) => (
                    <div
                      className={`flex items-center gap-x-2 cursor-pointer py-2 hover:bg-gray-100 ${currentLocale === locale.code ? 'font-semibold' : ''}`}
                      onClick={(e) => {
                        i18n.changeLanguage(locale.code)
                      }}
                    >
                      {locale.icon}
                      {t(locale.name_locale.key, {ns: locale.name_locale.ns})}
                    </div>
                  ))}
                </header>
              {/*<div className="w-full max-w-full flex justify-center md:justify-end">*/}
                <div className="max-w-full lg:max-w-[90%] px-0 sm:px-4 pb-16">
                  <header className="text-center w-full">
                    <img src={Logo} alt="Memory" className="mb-8 mx-auto" />
                  </header>

                  <p className="text-lg font-semibold">
                    {t('Hello', {ns:'common'})} {entityRequest && entityRequest.first_name} {entityRequest && entityRequest.last_name},
                  </p>
                  <p className="mt-10">
                    {t('Your-request-1', {ns:'entity-request'})}
                    <span className="block">
                      {entityRequest && (<span className="font-semibold">"{entityRequest.entity_name}"</span>)}
                    </span>
                    <span className="block text-grayPrimary text-sm">
                      {t('You-can-change-name-later', {ns:'entity-request'})}
                    </span>
                  </p>

                  <div className="mt-10 flex flex-col">
                    <p className="mb-2">
                      {t('Your-subdomain', {ns:'entity-request'})}
                    </p>
                    <div className="flex items-center">
                      <input
                        id="entity-name"
                        name="entity-name"
                        type="text"
                        value={entityFormName}
                        onChange={(e) => setEntityFormName(e.target.value)}
                        className="block w-1/2 sm:w-auto h-10 px-5 sm:text-sm border border-grayLight rounded-md disabled:bg-grayLight"
                        placeholder={"placeHolder"}
                        autoComplete="off"
                        maxLength={50}
                      />
                      <span className="block w-fit ml-2">.my-memory.io</span>
                    </div>

                    <div className="flex gap-y-2 items-center font-semibold mt-2">
                      {entitySlug}.my-memory.io
                      <span className="ml-2">
                        {domainLoading && (
                          <>
                            <RiLoader4Line className="h-4 w-4 animate-spin" />
                          </>
                        )}
                        {(!domainLoading && domainAvailable) && (
                          <HtmlTooltip
                            title={t('Domain-available', {ns: 'entity-request'})}
                            className=""
                            arrow={true}
                            placement="right"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 500 }}
                          >
                            <span>
                              <RiCheckLine className="h-5 w-5 cursor-pointer text-green-500 border border-2 border-green-500 rounded-full" />
                            </span>
                          </HtmlTooltip>
                        )}
                        {(!domainLoading && !domainAvailable) && (
                          <HtmlTooltip
                            title={t('Domain-unavailable', {ns: 'entity-request'})}
                            className=""
                            arrow={true}
                            placement="right"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 500 }}
                          >
                            <span>
                              <RiErrorWarningLine className="h-5 w-5 cursor-pointer text-red-600 bg-red-200 rounded-full" />
                            </span>
                          </HtmlTooltip>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mt-8 flex flex-col w-auto sm:w-64">
                    <label htmlFor="entity-name" className="block text-sm font-medium">
                      {t('Memory-default-locale', {ns: 'entity-request'})}
                    </label>
                    <LocalesSelector
                      currentLocale={memoryLocale}
                      setCurrentLocale={setMemoryLocale}
                    />
                  </div>

                  <div className="mt-4 flex flex-col w-auto sm:w-64">
                    <label htmlFor="entity-name" className="block text-sm font-medium">
                      {t('choose-your-plan', {ns: 'entity-request'})}
                    </label>

                    <select
                      id="plan"
                      name="plan"
                      className={`block w-full h-10 px-5 sm:text-sm border border-grayLight rounded-md disabled:bg-grayLight`}
                      onChange={(e) => handleChange(e)}
                      defaultValue={plan}
                    >
                      <option value="demo">demo</option>
                      <option value="pro">pro</option>
                    </select>
                  </div>

                  <div className="mt-10 flex font-semibold items-center flex-col text-center w-full">
                    <Button
                      theme={formLoading || isDisabled || !domainAvailable || domainLoading ? 'disabled' : ''}
                      size="small"
                      icon={formLoading ? <RiLoader4Line className="w-5 h-5 mr-2 animate-spin" /> : <RxRocket className="w-5 h-5 mr-2" />}
                      iconPosition="left"
                      actionType="action"
                      action={() => createEntity()}
                    >
                      {t('action.Start', {ns: 'common'})}
                    </Button>
                  </div>
                </div>
              </div>
              <div className={`w-full h-full bg-gray-200`}>
                <img src={Elephant} className={`object-cover w-full h-full`} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default EntityRequest