import React from 'react'
import { useTranslation } from 'react-i18next'
// Component
import { Link } from "react-router-dom"
import Loader from "../elements/loader/PageContentLoader"
import ContentIsEmpty from "../elements/divContainer/ContentIsEmpty"
import moment from "moment";


const TableProjects = ({ list, dataLoaded }) => {
  
  // Translations
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col overflow-auto pr-2.5 relative memoryScrollbar">
      {/* GRID HEAD */}
      <div className="grid grid-cols-4 w-full text-sm font-bold bg-gray-200 sticky top-0 z-10">
        <span className="p-2">{t('Id')}</span>
        <span className="p-2">{t('Name')}</span>
        <span className="p-2">{t('Plan')}</span>
        <span className="p-2">{t('date.Creation-date', {ns: 'common'})}</span>
      </div>

      {/* GRID BODY */}
      <div className="flex flex-col">

        {list && dataLoaded ?
          <>
            {list.length === 0 ?
              <ContentIsEmpty>
                {t('No-element')}
              </ContentIsEmpty>
            :
              list.map((item) => (
                <div key={item.id} className={`grid grid-cols-4 w-full border-b hover:bg-gray-100`}>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.id}>
                      {item.id}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.name ? item.name : item.id}>
                      {item.name ? item.name : item.id}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.plan ? item.plan : 'NC'}>
                      {item.plan ? item.plan : 'NC'}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.created_at ? moment(item.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}>
                      {item.created_at ? moment(item.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}
                    </span>
                  </Link>
                </div>
              ))
            }
          </>
        :
          <Loader className="h-48" />
        }
      </div>
    </div>
  )
}

export default TableProjects